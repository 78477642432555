import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    ResponsiveContainer,
    CartesianGrid,
  } from "recharts";

export default function TGLineChart(props:any){
    const {data, height, lines, xDataKey, allowAnimation} = props
    const colors = ["#4CAF50", "#2196F3", "#FFC107", "#E91E63", "#FF5722", "#9C27B0"]

    return (
        <ResponsiveContainer width="100%" height={height || 400}>
            <LineChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              {!!lines?.length && 
              <ul>
                  {lines.map((line: any, index: any)=>{
                      return <li key={index}>{line.label}</li>
                  })}
              </ul>}
              <XAxis dataKey={xDataKey || 'name'} />
              <YAxis />
              <Tooltip />
              <Legend />
              {!!lines.length && lines.map((line: any, index: any) => {
              return <Line
                isAnimationActive={allowAnimation || false}
                key={index}
                type="monotone"
                dataKey={line.dataKey}
                stroke={colors[index]}
                name={line.name}
              />})}
            </LineChart>
        </ResponsiveContainer>
    )
}
import { color } from "html2canvas/dist/types/css/types/color";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    ResponsiveContainer,
  } from "recharts";

export default function TGBarChart(props:any){
    const {data, bars, height, xDataKey, allowAnimation} = props
    const colors = ["#4CAF50", "#2196F3", "#FFC107", "#E91E63", "#FF5722", "#9C27B0"]







    return (
        <ResponsiveContainer width="100%" height={height || 300}>
          <BarChart data={data} barCategoryGap={10}>
            <XAxis dataKey={xDataKey || "name"} />
            <YAxis />
            <Tooltip />
            <Legend />
            {!!bars.length && bars.map((bar: any, index: any)=>{
                return <Bar
                isAnimationActive={allowAnimation || false}
                key={index}
                dataKey={bar.dataKey || "value"}
                fill={colors[index] || '#8884d8'}
                radius={[5, 5, 0, 0]}
                />
            })}
          </BarChart>
        </ResponsiveContainer>
    )
}
type TableRow = Record<string, any>; // A single row is an object with dynamic keys and values

type TableData = {
  name: string; // The name of the table
  row: TableRow[]; // Array of rows, where each row is an object
};

type DataTableProps = {
  data: TableData[]; // Array of tables
};

const DataTable = ({ data }: DataTableProps) => {
  return (
    <div>
      {data.map((table, tableIndex) => {
        const { name, row } = table; // Extract table name and rows
        const headers = row && row.length > 0 ? Object.keys(row[0]) : []; // Dynamically extract headers

        return (
          <div key={tableIndex} style={{ marginBottom: "20px" }}>
            <h3>{name}</h3> {/* Dynamic table name */}
            <table style={{ borderCollapse: "collapse", width: "100%" }}>
              <thead>
                <tr>
                  {headers.map((header) => (
                    <th
                      key={header}
                      style={{
                        border: "1px solid black",
                        padding: "8px",
                        textAlign: "center",
                        backgroundColor: "#f2f2f2",
                      }}
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {row.map((rowData, rowIndex) => (
                  <tr key={rowIndex}>
                    {headers.map((header) => (
                      <td
                        key={header}
                        style={{
                          border: "1px solid black",
                          padding: "8px",
                          textAlign: "center",
                        }}
                      >
                        {rowData[header]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );
      })}
    </div>
  );
};

export default DataTable;

import {
  Box,
  Button,
  Card,
  Container,
  Flex,
  Grid,
  Group,
  Header,
  Image,
  List,
  rem,
  Space,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { useGoogleLogin } from "@react-oauth/google";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getUserSpaces, signinWithGoogle } from "../../lib/APIService";
import { Carousel } from "@mantine/carousel";
import { getRoleIcon, getRoleIconColored } from "../../lib/Utils";

const LandingNew = () => {
  const navigate = useNavigate();
  const theme = useMantineTheme();

  const login = useGoogleLogin({
    flow: "auth-code",
    onSuccess: async (codeResponse) => {
      const response = await signinWithGoogle(codeResponse.code);
      if (response.tokens) {
        localStorage.setItem("accessToken", response.tokens);
        localStorage.setItem("email", response.email);
        localStorage.setItem("username", response.username);
        localStorage.setItem("image", response.image);
        localStorage.setItem("firstName", response.firstName);
        localStorage.setItem("lastName", response.lastName);
        localStorage.setItem("role", response.role);
        const spaces = await getUserSpaces();
        if (
          spaces.organizations?.length &&
          spaces.organizations[0].workspaces?.length
        ) {
          localStorage.setItem(
            "workspace_id",
            spaces.organizations[0].workspaces[0].id
          );
        }
        navigate("/home/chat");
      }
      console.log(response);
    },
  });

  const CarouselSlide = ({ role, img }: any) => {
    return (
      <Card shadow="sm" padding="lg" radius="md" withBorder>
        <Card.Section>
          <Image src={img} width={240} height={180} alt={role} />
        </Card.Section>
        {/* <Group position="center" mt="md" mb="xs">
          <Text weight={400}>{role}</Text>
        </Group> */}
      </Card>
    );
  };

  const AgentBlock = ({ image, role }: any) => {
    return (
      <Group
        p={8}
        spacing={4}
        style={{ backgroundColor: "#D6EEFF", borderRadius: "10px" }}
      >
        <Image width={24} src={image}></Image>
        <Text size={16} color="#747A80" fw={500}>
          {role}
        </Text>
      </Group>
    );
  };

  useEffect(() => {
    if (!!localStorage.getItem("accessToken")) {
      navigate("/home");
    }
  }, []);

  return (
    <Stack style={{ minHeight: window.innerHeight }} justify="flex-start">
      <Flex justify="flex-end" direction={"row"}>
        {/* <Image
          pl={32}
          pt={16}
          height={90}
          width={90}
          src={"/salesforce.svg"}
          style={{ alignSelf: "center" }}
        ></Image> */}
        <Group className="margin">
          <Button radius={"md"} onClick={() => login()}>
            Sign in
          </Button>
          <Space w="xl" />
        </Group>
      </Flex>
      <Stack ml={0} mt={150} justify="space-evenly">
        <Stack spacing={0} align="center">
          <Group align={"center"}>
            <Image width={48} src="/md-fs.svg" />
            {/* <Image width={48} src="/TrustGenie.png" /> */}

            <div
              style={{
                marginBottom: "2px",
              }}
            >
              <Title
                ff={"Merriweather Regular"}
                weight={500}
                size={59}
                lh={"70px"}
                color="#072D57"
              >
                {/* Trust Genie */}
                Fusion Security
              </Title>
            </div>
          </Group>
          <Group>
            <Title
              align="center"
              lh={"24px"}
              size={24}
              weight={500}
              color="#737980"
              pl={22}
            >
              Powered by Agentic AI{/* Powered by Agentforce on Einstein 1 */}
            </Title>
          </Group>
        </Stack>
        <Group position="center" p={32} spacing={64}>
          <Stack spacing={0}>
            <Title
              ml={24}
              color={"#072D57"}
              size={48}
              fw={300}
              style={{ fontFamily: "SalesforceSans" }}
              align="center"
            >
              Get <span style={{ color: "#1785E6" }}>10X faster</span> on Trust!
            </Title>
            <Title align="left" fw={300} size={24} color={"#072D57"}>
              {" "}
              AI agents for Security, Availability, Cost & Operations Excellence
            </Title>
            <Box ml={64} w={window.innerWidth / 2.5}>
              <Text
                mt={16}
                color={"#737980"}
                size={18}
                fw={400}
                align="justify"
              >
                <List ml={64}>
                  <List.Item>
                    <Text
                      color={"#072D57"}
                      size={18}
                      fw={400}
                      align="justify"
                      style={{ fontFamily: "SalesforceSans" }}
                    >
                      24x7 OOTB AI Trust Agents
                    </Text>
                  </List.Item>
                  <List.Item>
                    <Text color={"#072D57"} size={18} fw={400} align="justify">
                      Seamless Tool Integration
                    </Text>
                  </List.Item>
                  <List.Item>
                    <Text
                      color={"#072D57"}
                      size={18}
                      fw={400}
                      align="justify"
                      style={{ fontFamily: "SalesforceSans" }}
                    >
                      Unified Data Fabric
                    </Text>
                  </List.Item>
                  <List.Item>
                    <Text
                      color={"#072D57"}
                      size={18}
                      fw={400}
                      align="justify"
                      style={{ fontFamily: "SalesforceSans" }}
                    >
                      Instant Insights and Data Visibility
                    </Text>
                  </List.Item>
                  <List.Item>
                    <Text
                      color={"#072D57"}
                      size={18}
                      fw={400}
                      align="justify"
                      style={{ fontFamily: "SalesforceSans" }}
                    >
                      Conversational Guidance
                    </Text>
                  </List.Item>

                  <List.Item>
                    <Text
                      color={"#072D57"}
                      size={18}
                      fw={400}
                      align="justify"
                      style={{ fontFamily: "SalesforceSans" }}
                    >
                      Cognitive Automation
                    </Text>
                  </List.Item>
                  <List.Item>
                    <Text
                      color={"#072D57"}
                      size={18}
                      fw={400}
                      align="justify"
                      style={{ fontFamily: "SalesforceSans" }}
                    >
                      Unparalleled User Experience
                    </Text>
                  </List.Item>
                </List>
              </Text>
            </Box>
          </Stack>
          <Stack>
            <Image width={600} src="/landingnew-2.png"></Image>
          </Stack>
        </Group>
      </Stack>
      {/* <Image
        pl={32}
        pb={32}
        pt={0}
        height={180}
        width={400}
        src={"./ashok_banner.png"}
        style={{ alignSelf: "left" }}
        fit="fill"
      ></Image> */}
      {/* <Stack spacing={0} align={"center"} mb={64}>
        <Text>AI Agents</Text>
        <Group mt={16} align={"center"} spacing={64}>
          <AgentBlock
            role={"Trust Assurance"}
            image={getRoleIconColored("trust-expert")}
          />
          <AgentBlock
            role={"Cloud Analyst"}
            image={getRoleIconColored("cloud-analyst")}
          />
          <AgentBlock
            role={"Security Analyst"}
            image={getRoleIconColored("security-analyst")}
          />
          <AgentBlock
            role={"Anypoint Trust"}
            image={getRoleIconColored("anypoint-trust")}
          />
          <AgentBlock
            role={"Supervisor"}
            image={getRoleIconColored("multi-agent")}
          />
          <AgentBlock
            role={"Custom Agents"}
            image={getRoleIconColored("custom-agent")}
          />
        </Group>
      </Stack>
      {/* <Stack spacing={0} align={"center"} mt={16} mb={16}>
        <Text>Runs on</Text>
        <Group mt={-24} align={"center"} spacing={64}>
          <Image width={156} src="/mulesoft.png"></Image>
          <Group mt={16}>
            <Image width={64} src="/datacloud.png"></Image>
            <Text size={"xl"} fw={800} color={"blue"}>
              Data Cloud
            </Text>
          </Group>
          <Image mt={16} width={120} src="/TableauLogo.png"></Image>
          <Image mt={16} width={60} src="/einsteinblue.svg"></Image>
          <Image mt={24} width={100} src="/slack.png"></Image>
          <Image mt={16} width={120} src="/hyperforce-closer.png"></Image>
        </Group>
      </Stack> */}
    </Stack>
  );
};
export default LandingNew;

import { FocusTrap, Image, Textarea } from "@mantine/core";
import { IconSend2 } from "@tabler/icons-react";
import { useState } from "react";
export default function ChatInput(props: any) {
  const { disabled, sendQuery, downloadPdf } = props;
  const [message, setMessage] = useState("");

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      // if (message === "Send me a pdf") {
      //   downloadPdf();
      // }
      sendQuery(message);
      setMessage("");
    }
  };

  return (
    <div
      style={{
        position: "absolute",
        bottom: 0,
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="field round border no-margin" style={{ flex: "0 0 85%" }}>
        <input
          type="text"
          placeholder="Ask me something"
          value={message}
          onKeyDown={handleKeyDown}
          onChange={(e) => setMessage(e.currentTarget.value)}
        />
      </div>
      <a
        onClick={() => {
          sendQuery(message);
          setMessage("");
        }}
        href=""
      >
        {" "}
        <i className="large tiny-margin">send</i>
      </a>
    </div>
  );
}
